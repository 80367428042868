import Agent from './_agent';

const Auth = {
  login: ({ hash }) => Agent.post(`auth/login/${hash}`),
};

const Common = {
  time: () => Agent.get('time'),
  config: () => Agent.get('config'),
};

const Game = {
  score: ({ score }) => Agent.post('score', { score }),
  share: ({ score }) => Agent.get(`share/${score}`),
};

export default {
  Auth,
  Common,
  Game,
};
